/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


// JQUERY Form validator: http://www.formvalidator.net //

var contactFormMan = function () {


    var initContactForm = function (formSelector, submitBtnSelector, serverURL) {

        $.validate({
            form: formSelector,
            validateOnBlur: false,
            showHelpOnFocus: false,
            addSuggestions: false,
            onElementValidate: function (valid, $el, $form, errorMess) {



                switch ($el.attr('name')) {


                    case 'name':

                        if (valid) {

                            $('#name-error-label').text('');

                        } else {

                            $('#name-error-label').text('El nombre es obligatorio');

                        }


                        break;




                    case 'email':

                        if (valid) {

                            $('#email-error-label').text('');

                        } else {

                            $('#email-error-label').text('Indica un correo electrónico válido');

                        }


                        break;


                    case 'tlf':


                        if (!valid) {

                            $('#tlf-error-label').text('Indica un número de teléfono válido');

                        }


                        break;



                    case 'msg':

                        if (valid) {

                            $('#msg-error-label').text('');

                        } else {

                            $('#msg-error-label').text('El mensaje es obligatorio');

                        }


                        break;




                }




            },
            onError: function ($form) {

                checkOptionalValidationFields();
                
                $("#sending-msg-disclaimer").modal('hide');
                
            },
            onSuccess: function ($form) {
                
                checkOptionalValidationFields();
                
                $("#sending-msg-disclaimer").modal('show');

                var formDataJSON = {
                    data: $(formSelector).serializeArray()

                };

                var formData = JSON.stringify(formDataJSON);


                $.ajax({
                    type: 'POST',
                    url: serverURL,
                    data: formData,
                    success: function () {

                        onSubmitSuccess();
                    },
                    error: function () {

                        onSubmitError();
                    },
                    dataType: 'json',
                    contentType: 'application/json'

                });
                return false; // Will stop the submission of the form
            }
        });
    };



    var checkOptionalValidationFields = function () {

        if ($('#tlf').val() === '') {

            $('#tlf-error-label').text('');

        }

    };


    var onSubmitSuccess = function () {

        window.location = 'msg-received.html';
    };


    var onSubmitError = function () {

        window.location = 'msg-generic-error.html';
    };


    return {
        initContactForm: initContactForm

    };
}();