/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var redirector = (function() {
    
    
    
    
    var init = function() {
        
        initGoHomeLinks();  
        
    };
    
    
    var initGoHomeLinks = function() {
        
        $('.mnro-go-home-link').click( function() {
            
           window.location = 'index.html'; 
            
        });
        
    };
  
    
    
    
    /* *** PUBLIC API *** */
    return {
        
        init: init
        
    };
    
    
})();