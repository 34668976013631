/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var textEffectsMan = function () {


    // METHODS

    var applyConsoleEffect = function (containerSelector, delay, onEndCallback ) {
        
        
        
        

        var cursorBlink = false;

        var text = $(containerSelector).attr('data-text');

        var textChars = text.split('');

        var printedChars = "";

        var textCharsLength = textChars.length;

        var index = 0;

        var blinkCursor = function () {

            if( $(containerSelector + ' .cursor').hasClass('mnro-invisible') ) {
                
                $(containerSelector + ' .cursor').removeClass('mnro-invisible');
            
            } else {
                
                $(containerSelector + ' .cursor').addClass('mnro-invisible');
            }
            
            if( cursorBlink ) {
                
                setTimeout(blinkCursor, delay * 3 );
            }


        };
        
        
        


        var printCharConsole = function () {

            printedChars += textChars[index];

            $(containerSelector + " .text").text(printedChars);

            

            index++;

            if (index < textCharsLength) {

                setTimeout(printCharConsole, delay);

                
            } else {
                
                if( onEndCallback && typeof onEndCallback !== 'undefined' ) {
                    
                    onEndCallback();
                    
                    
                } else {
                    
                    cursorBlink = true;
            
                    blinkCursor();
                
                    
                }
                
                
                
            }

        };


        printCharConsole();



    };






    // PUBLIC API
    return {

        applyConsoleEffect: applyConsoleEffect

    };


}();