/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */






var Progress = function (element) {

    this.context = element.getContext("2d");
    this.refElement = element.parentNode;
    this.loaded = 0;
    this.start = 4.72;
    this.width = this.context.canvas.width;
    this.height = this.context.canvas.height;
    this.total = parseInt(this.refElement.dataset.percent, 10);
    this.timer = null;

    this.diff = 0;

    this.init();
};

Progress.prototype = {
    init: function () {
        var self = this;
        self.timer = setInterval(function () {
            self.run();
        }, 25);
    },
    run: function () {
        var self = this;

        self.diff = ((self.loaded / 100) * Math.PI * 2 * 10).toFixed(2);
        self.context.clearRect(0, 0, self.width, self.height);
        self.context.lineWidth = 10;
        self.context.fillStyle = "#285571";
        self.context.strokeStyle = "#70a9cd";
        self.context.textAlign = "center";

        self.context.fillText(self.loaded + "%", self.width * .5, self.height * .5 + 2, self.width);
        self.context.beginPath();
        self.context.arc(35, 35, 30, self.start, self.diff / 10 + self.start, false);
        self.context.stroke();

        if (self.loaded >= self.total) {
            clearInterval(self.timer);
        }

        self.loaded++;
    }
};

var CircularSkillBar = function (elements) {
    this.bars = document.querySelectorAll(elements);
    if (this.bars.length > 0) {
        this.init();
    }
};

CircularSkillBar.prototype = {
    init: function () {
        this.tick = 25;
        this.progress();

    },
    progress: function () {


        var self = this;
        var index = 0;

        var canvasCount = self.bars.length;

        var firstCanvas = self.bars[0].querySelector("canvas");



        //var firstProg = new Progress(firstCanvas);



        var timer = setInterval(function () {



            var canvas = self.bars[index].querySelector("canvas");
            var prog = new Progress(canvas);

            if (index === (canvasCount - 1)) {
                clearInterval(timer);

            } else {

                index++;

            }

        }, self.tick * 20);

    }
};



