/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var navSubmenuMan = ( function () {

    var initNavSubmenu = function (navSelector) {

        var $features = $( navSelector + " .features");
        var position = $features.offset().top + 40;

        $(window).scroll(function () {
            var top = $(this).scrollTop();

            if (top > position && !$features.hasClass("fixed")) {
                $features.addClass("fixed fadeInDown");
            } else if (top < position && $features.hasClass("fixed")) {
                $features.removeClass("fixed fadeInDown");
                //$features.find("li:eq(0) .nav-link").addClass("active");
            }
        });

        $('body').scrollspy({
            target: navSelector
        });

        setTimeout(function () {
            $(".nav-link").removeClass("featureFadeInDown");
        }, 1500);

    };







    // *** PUBLIC API
    return {

        initNavSubmenu: initNavSubmenu
    };



})();